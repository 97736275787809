import * as React from 'react';
import { signInWithRedirect } from 'aws-amplify/auth';
import { Button, Card, Grid, Input, Stack, Textarea, Alert, LinearProgress } from '@mui/joy';
import CodeEditor from './CodeEditor';
import { DEFAULT_CODE } from '../utils';

import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { SessionManager } from '../Sessions';
import { useNavigate } from 'react-router-dom';

export type NewChallengeProps = {
  sessionManager: SessionManager;
};

export default function NewChallenge({sessionManager} : NewChallengeProps) {

  const [code, setCode] = React.useState<string>(DEFAULT_CODE);
  const [name, setName] = React.useState<string>("");
  const [shortDesc, setShortDesc] = React.useState<string>("");
  const [longDesc, setLongDesc] = React.useState<string>("");
  const [submitEnabled, setSubmitEnabled] = React.useState<boolean>(false);
  const [isSaving, setsaving] = React.useState<boolean>(false);

  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  React.useEffect(() => {
    setSubmitEnabled(name.length > 0 && shortDesc.length > 0 && longDesc.length > 0 && code.length > 0);
  }, [setSubmitEnabled, code, name, shortDesc, longDesc]);

  function createChallenge() {
    setsaving(true);
    sessionManager.createChallenge({
      name: name,
      fullDetails: longDesc,
      description: shortDesc,
      code: code
    }, navigate);
  }

  if (user === undefined) {
    return (<div style={{overflow: 'hidden', padding: '4rem'}}>
      <Alert variant="soft" color="danger"
        endDecorator={
          <React.Fragment>
            <Button
              size="sm"
              color="danger"
              sx={{ alignSelf: 'center', borderRadius: 'sm'}}
              endDecorator={<LoginRoundedIcon />}
              onClick={() => signInWithRedirect({
                provider: 'Google'
              })}
            >
              Sign In
            </Button>
          </React.Fragment>
        }>
        You must be logged in to create a new challenge.
        </Alert>
    </div>);
  }
  return (<div style={{overflow: 'hidden'}}>
      <Grid container spacing={2} sx={{ p: 4, flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Grid xs={12} sx={{flexGrow: 1}}>
          <Card>
            <Stack spacing={1}>
              <Input placeholder="Challenge Name" value={name} onChange={e => setName(e.target.value)} required />
              <Input placeholder="Short Description" value={shortDesc} onChange={e => setShortDesc(e.target.value)}required />
              <Textarea placeholder="Long Description" minRows={3} value={longDesc} onChange={e => setLongDesc(e.target.value)} required />
              <CodeEditor codeContents={code} onChange={c => setCode(c)} readonly={false}/>
              { isSaving && (<LinearProgress/>) }
              <Button onClick={createChallenge} type="submit" disabled={!submitEnabled || isSaving}>Test Challenge</Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </div>);
}
