import { NavigateFunction } from "react-router-dom";
import { Challenge, ChatProps, CreateChallengeInput, MessageProps, SearchResult, Session, UpdateChallengeInput } from "./types";
import { API_ENDPOINT } from "./utils";
import {v4 as uuidv4} from 'uuid';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Dispatch, SetStateAction } from "react";

export class SessionManager {
    sessions: Record<string, Session> = {};
    currentChallenge?: Challenge = undefined;
    currentSession?: Session = undefined;
    hooks: Record<number, (s : Session | undefined) => void> = {};
    challengeListHooks: Record<number, (sr : SearchResult) => void> = {};
    chatHooks: Record<number, (s : ChatProps) => void> = {};
    hookIndex: number = 0;
    challengeList?: SearchResult = undefined;
    challengeListLastJwt?: string = undefined;
    errorListener?: (e : string) => void = undefined;

    private getSession() {
        if (this.currentChallenge === undefined) {
            if (this.currentSession !== undefined) {
                this.currentSession = undefined;
                Object.entries(this.hooks).map(i => i[1]).forEach(h => h(undefined));
                this.chatHooks = {};
            }
            return;
        }

        if (!(this.currentChallenge!.id in this.sessions)) {
            this.sessions[this.currentChallenge!.id] = {
                id: uuidv4(),
                challenge: this.currentChallenge!,
                chat: {
                    turns: []
                }
            };
        }

        const newSession = this.sessions[this.currentChallenge!.id];
        if (newSession !== this.currentSession) {
            this.currentSession = newSession;
            Object.entries(this.hooks).map(i => i[1]).forEach(h => h(newSession));
            this.chatHooks = {};
        }
    }

    getChallengeList() {
        fetchAuthSession().then(res => {
            const userJwt = res.tokens?.accessToken.toString();
            if (this.challengeList !== undefined && this.challengeListLastJwt === userJwt) {
                Object.entries(this.challengeListHooks).map(i => i[1]).forEach(h => h(this.challengeList!));
            }
            this.searchChallenges(undefined, undefined).then(sr => {
                this.challengeList = sr;
                this.challengeListLastJwt = userJwt;
                Object.entries(this.challengeListHooks).map(i => i[1]).forEach(h => h(sr));
            });
        });
    }

    addChallengeListHook(hook: (sr : SearchResult) => void) {
        this.challengeListHooks[this.hookIndex] = hook;
        this.hookIndex += 1;
        this.getChallengeList();
        return this.hookIndex - 1;
    }

    addErrorListener(errorListener: (errorMessage: string) => void) {
        this.errorListener = errorListener;
    }

    async searchChallenges(searchFilter: string | undefined,
                           challengeId: string | undefined) : Promise<SearchResult> {
        const userJwt = await fetchAuthSession()
            .then(res => res.tokens?.accessToken.toString())
            .catch(e => this.error(e));
        const headers : Record<string, string> = {};
        let params = '';
        if (searchFilter !== undefined) {
            if (params === '') {
                params += '?'
            } else {
                params += '&'
            }
            params += 'search=' + searchFilter!;
        }
        if (challengeId !== undefined) {
            if (params === '') {
                params += '?'
            } else {
                params += '&'
            }
            params += 'id=' + challengeId!;
        }
        if (userJwt !== undefined) {
            headers["Authorization"] = `Bearer ${userJwt}`
        }
        return await fetch(API_ENDPOINT + '/challenge' + params, {
            method: "GET",
            mode: "cors",
            headers: headers
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            return response;
        })
        .then(response => response.json())
        .then(json => json as SearchResult)
        .catch(e => {
            this.error(e);
            return {};
        });
    }

    async updateChat(session: Session, newMessage: MessageProps) {
        session.chat.turns.push(newMessage);
        const userJwt = await fetchAuthSession()
            .then(res => res.tokens?.accessToken.toString())
            .catch(e => this.error(e));
        const headers : Record<string, string> = {};
        if (userJwt !== undefined) {
            headers["Authorization"] = `Bearer ${userJwt}`
        }
        return fetch(API_ENDPOINT + '/session/' + session.id, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                challengeId: session.challenge.id,
                sessionId: session.id,
                turns: session.chat.turns
            }),
            headers: headers
        })
        .then(response => { return response.json() })
        .then(json => (json as ChatProps))
        .then(chatProps => {
            session.chat = chatProps;
            Object.entries(this.chatHooks).map(i => i[1]).forEach(h => h(session.chat));
        })
        .catch(e => this.error(e));
    }

    async createChallenge(newChallenge: CreateChallengeInput, navigate: NavigateFunction) {
        const userJwt = await fetchAuthSession()
            .then(res => res.tokens?.accessToken.toString())
            .catch(e => this.error(e));
        const headers : Record<string, string> = {};
        if (userJwt !== undefined) {
            headers["Authorization"] = `Bearer ${userJwt}`
        }
        return fetch(API_ENDPOINT + '/challenge', {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(newChallenge),
            headers: headers
        })
        .then(response => { return response.json() })
        .then(json => (json as Challenge))
        .then(challenge => {
            this.challengeList = undefined;
            this.getChallengeList();
            this.switchChallenge(challenge, navigate);
        })
        .catch(e => this.error(e));
    }

    async editChallenge(newChallenge: UpdateChallengeInput) {
        const userJwt = await fetchAuthSession().then(res => res.tokens?.accessToken.toString());
        const headers : Record<string, string> = {};
        if (userJwt !== undefined) {
            headers["Authorization"] = `Bearer ${userJwt}`
        }
        return fetch(API_ENDPOINT + '/challenge', {
            method: "PUT",
            mode: "cors",
            body: JSON.stringify(newChallenge),
            headers: headers
        })
        .then(response => { return response.json() })
        .then(json => (json as Challenge))
        .then(challenge => {
            this.currentChallenge = undefined;
            this.currentSession = undefined;
            this.getChallengeList();
            this.switchChallenge(challenge, () => this.resetCurrent());
        })
        .catch(e => this.error(e));
    };

    async deleteChallenge(challenge: Challenge, navigate: NavigateFunction) {
        const userJwt = await fetchAuthSession().then(res => res.tokens?.accessToken.toString())
            .catch(e => this.error(e));
        const headers : Record<string, string> = {};
        if (userJwt !== undefined) {
            headers["Authorization"] = `Bearer ${userJwt}`
        }
        return fetch(API_ENDPOINT + '/challenge', {
            method: "DELETE",
            mode: "cors",
            body: JSON.stringify({id: challenge.id}),
            headers: headers
        })
        .then(() => {
            this.currentChallenge = undefined;
            this.currentSession = undefined;
            this.getChallengeList();
            navigate("/")
        })
        .catch(e => this.error(e));
    }

    private error(e: any): void {
        if (this.errorListener !== undefined) {
            this.errorListener(e.toString())
        }
    }

    resetCurrent() {
        if (this.currentChallenge === undefined) {
            throw new Error("no challenge selected");
        }
        delete this.sessions[this.currentChallenge!.id];
        this.getSession();
    }

    switchChallenge(challenge: Challenge | undefined, navigate: NavigateFunction) {
        if (challenge?.id === this.currentChallenge?.id) {
            if (challenge !== undefined) {
                navigate("/challenge/" + challenge!.id)
            }
            return;
        }
        this.currentChallenge = challenge;
        this.getSession();
        navigate("/challenge/" + this.currentChallenge!.id)
    }

    addSessionHook(hook: (s: Session | undefined) => void): number {
        this.hooks[this.hookIndex] = hook;
        this.hookIndex += 1;
        if (this.currentSession === undefined) {
            if (this.currentChallenge !== undefined) {
                this.getSession();
            }
        } else {
            hook(this.currentSession!);
        }
        return this.hookIndex - 1;
    }

    removeSessionHook(handle: number): any {
        delete this.hooks[handle];
    }

    addChatHook(session: Session, hook: (s: ChatProps) => void): number {
        this.chatHooks[this.hookIndex] = hook;
        this.hookIndex += 1;
        hook(session.chat);
        return this.hookIndex - 1;
    }

    removeChatHook(handle: number) {
        delete this.chatHooks[handle];
    }

    removeChallengeListHook(handle: number) {
        delete this.challengeListHooks[handle];
    }
    
}