import * as React from 'react';
import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { MessageProps } from '../types';

type ChatBubbleProps = MessageProps & {
  variant: 'sent' | 'received';
};

export default function ChatBubble(props: ChatBubbleProps) {
  const { message, variant } = props;
  const isSent = variant === 'sent';
  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
        <Box
            sx={{ position: 'relative' }}
        >
            <Sheet
                color={isSent ? 'primary' : 'neutral'}
                variant={isSent ? 'solid' : 'soft'}
                sx={{
                    p: 1.25,
                    borderRadius: 'lg',
                    borderTopRightRadius: isSent ? 0 : 'lg',
                    borderTopLeftRadius: isSent ? 'lg' : 0,
                    marginRight: 2,
                    backgroundColor: isSent
                    ? 'var(--joy-palette-primary-solidBg)'
                    : 'background.body',
                }}
                >
                <Typography
                    level="body-sm"
                    sx={{
                    color: isSent
                        ? 'var(--joy-palette-common-white)'
                        : 'var(--joy-palette-text-primary)',
                    }}
                >
                    {message}
                </Typography>
            </Sheet>
        </Box>
    </Box>
  );
}
