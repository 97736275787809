import * as React from 'react';
import ChallengeCard from './ChallengeCard';
import { LinearProgress, Stack, Typography } from '@mui/joy';
import { SessionManager } from '../Sessions';
import { SearchResult } from '../types';

export interface ChallengeBrowserProps {
  sessionManager: SessionManager;
}

export default function ChallengeBrowser({sessionManager} : ChallengeBrowserProps) {

  const [searchResults, setSearchResults] = React.useState<SearchResult>({});

  React.useEffect(() => {
    const handle = sessionManager.addChallengeListHook(c => setSearchResults(c));
    return () => sessionManager.removeChallengeListHook(handle);
  }, [sessionManager]);

  return Object.entries(searchResults).length === 0 ? (<div style={{overflow: 'hidden', padding: '4rem'}}><LinearProgress variant="soft" /></div>) :
      (<div style={{overflow: 'hidden'}}>{Object.entries(searchResults).map(x => {
          const category = x[0];
          const challenges = x[1];
          return (<>
            <Typography level="h2" component="h1" sx={{ pl: 4, pt: 4, pr: 4}}>{category}</Typography>
            <Stack spacing={1} direction="row" flexWrap="wrap" sx={{gap: 4, p: 4 }} useFlexGap>
              {challenges.map(c => (<ChallengeCard challenge={c}/>))}
            </Stack>
          </>);
        })}</div>);
}
