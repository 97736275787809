import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import { ChatProps, MessageProps, Session } from '../types';
import { Alert, Avatar, Button, LinearProgress, Typography } from '@mui/joy';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import FaceIcon from '@mui/icons-material/Face';
import { SessionManager } from '../Sessions';

type MessagesPaneProps = {
  sendMessage: (newMessage: MessageProps) => Promise<void>;
  session: Session;
  sessionManager: SessionManager;
};

export default function MessagesPane({ session, sendMessage, sessionManager}: MessagesPaneProps) {
  const [textAreaValue, setTextAreaValue] = React.useState<string>('');
  const [chat, setChat] = React.useState<ChatProps>();
  const [sending, setSending] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handle = sessionManager.addChatHook(session, c => setChat(c));
    return () => sessionManager.removeChatHook(handle);
  }, [session, sessionManager]);

  return chat === undefined ? null : (
    <Sheet
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          px: 2, py: 3,
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {chat!.turns.map((message: MessageProps, index: number) => {
            const isYou = message.role === 'human';
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                flexDirection={isYou ? 'row-reverse' : 'row'}
              >
                {isYou ? (
                  <Avatar
                    variant="outlined"
                    size="sm"
                  >
                    <FaceIcon/>
                  </Avatar>
                ) : (
                  <Avatar
                    variant="outlined"
                    size="sm"
                  >
                    <SmartToyIcon/>
                  </Avatar>
                )}
                <ChatBubble variant={isYou ? 'sent' : 'received'} {...message} />
              </Stack>
            );
          })}
          {
            chat!.status === 'success' && (
              <Alert
              variant="soft"
              color="success"
              invertedColors
              sx={{ alignItems: 'flex-start', gap: '1rem' }}
              endDecorator={
                <React.Fragment>
                  <Button
                    size="sm"
                    color="neutral"
                    sx={{ alignSelf: 'center', borderRadius: 'sm'}}
                    endDecorator={<RefreshRoundedIcon />}
                    onClick={() => sessionManager.resetCurrent()}
                  >
                    Go Again!
                  </Button>
                </React.Fragment>
              }
            >
              <Box sx={{ flex: 1 }} justifyContent="center" justifyItems="center">
                <Typography level="title-md">Congratulations!</Typography>
                <Typography level="body-md">
                  You have passed this challenge!
                </Typography>
              </Box>
            </Alert>
            )
          }

          {
            chat!.status === 'fail' && (
              <Alert
              variant="soft"
              color="danger"
              invertedColors
              sx={{ alignItems: 'flex-start', gap: '1rem' }}
              endDecorator={
                <React.Fragment>
                  <Button
                    size="sm"
                    color="neutral"
                    sx={{ alignSelf: 'center', borderRadius: 'sm'}}
                    endDecorator={<RefreshRoundedIcon />}
                    onClick={() => sessionManager.resetCurrent()}
                  >
                    Try Again
                  </Button>
                </React.Fragment>
              }
            >
              <Box sx={{ flex: 1 }} justifyContent="center" justifyItems="center">
                <Typography level="title-md">Game Over!</Typography>
                <Typography level="body-md">
                  Better luck next time!
                </Typography>

              </Box>
            </Alert>
            )
          }

          {
            sending && (<LinearProgress variant="soft" />)
          }
        </Stack>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        disabled={sending || chat!.status !== undefined}
        onSubmit={() => {
            setSending(true);
            sendMessage({
              role: 'human',
              message: textAreaValue
            }).then(() => setSending(false));
          }
        }
        sessionManager={sessionManager}
      />
    </Sheet>
  );
}
