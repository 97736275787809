import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Card from '@mui/joy/Card';
import { Button, Alert, Grid, LinearProgress, Stack, TabPanel, Typography, Input, Textarea, Switch, Box } from '@mui/joy';
import MessagesPane from './MessagesPane';
import CodeEditor from './CodeEditor';
import { SessionManager } from '../Sessions';
import { Session } from '../types';
import { useNavigate, useParams } from 'react-router-dom';

export interface ContentWindowProps {
  session: Session;
  sessionManager: SessionManager;
}

export default function ContentWindow({session, sessionManager} : ContentWindowProps) {

  const navigate = useNavigate();
  const { challengeId } = useParams();

  const [name, setName] = React.useState<string>("");
  const [shortDesc, setShortDesc] = React.useState<string>("");
  const [longDesc, setLongDesc] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [isSaving, setsaving] = React.useState<boolean>(false);
  const [published, setPublished] = React.useState<boolean>(false);

  React.useEffect(() => {
    setCode(session?.challenge.code || "");
    setName(session?.challenge.name || "");
    setShortDesc(session?.challenge.description || "");
    setLongDesc(session?.challenge.fullDetails || "");
    setPublished(session?.challenge.published);
  }, [session, setCode, setName, setShortDesc, setLongDesc, setPublished]);


  React.useEffect(() => {
    setHasChanged((session?.challenge.admin || false)
                  && ((name !== session.challenge.name)
                  || (shortDesc !== session.challenge.description)
                  || (longDesc !== session.challenge.fullDetails)
                  || (code !== session.challenge.code)
                  || (published !== session.challenge.published)));
  }, [session, name, shortDesc, longDesc, code, published, setHasChanged])

  React.useEffect(() => {
    if (session === undefined || session!.challenge.id !== challengeId) {
      sessionManager.searchChallenges(undefined, challengeId).then(results => {
        if (results["results"].length === 0) {
          navigate("/");
        }
        sessionManager.switchChallenge(results["results"][0], navigate)
      });
    }
  }, [session, sessionManager, challengeId, navigate]);

  function saveChallenge() {
    setsaving(true);
    sessionManager.editChallenge({
      id: session.challenge.id,
      name: name,
      fullDetails: longDesc,
      description: shortDesc,
      code: code,
      published: published
    }).then(
      () => setsaving(false)
    )
  };

  function deleteChallege() {
    setsaving(true);
    sessionManager.deleteChallenge(session.challenge, navigate);
  };

  return session === undefined ? (<div style={{overflow: 'hidden', padding: '4rem'}}><LinearProgress variant="soft" /></div>) : (
    <div style={{overflow: 'hidden'}}>
      <Grid container spacing={2} sx={{ p: 4, flex: 1, display: 'flex', flexDirection: 'column'}}>
        <Grid xs={12} sx={{flexShrink: 1}}>
          <Card>{session.challenge.fullDetails}</Card>
        </Grid>
        <Grid xs={12} sx={{flexGrow: 1}}>
          <Card>
            <Tabs
            defaultValue={0}
            sx={{
              bgcolor: 'transparent',
              height: '100%'
            }}
          >
              <TabList
                tabFlex={1}
                size="sm"
                defaultValue={0}
                sx={{
                  pl: { xs: 0, md: 4 },
                  justifyContent: 'left',
                  [`&& .${tabClasses.root}`]: {
                    fontWeight: '600',
                    flex: 'initial',
                    color: 'text.tertiary',
                    [`&.${tabClasses.selected}`]: {
                      bgcolor: 'transparent',
                      color: 'text.primary',
                      '&::after': {
                        height: '2px',
                        bgcolor: 'primary.500',
                      },
                    },
                  },
                }}
              >
                { session.challenge.admin && (
                  <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={-1}>
                    Edit Info
                  </Tab>
                )}
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
                  Play
                </Tab>
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
                  Source
                </Tab>
                <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={2}>
                  Analysis
                </Tab>
                { session.challenge.admin && (
                  <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={3}>
                    Delete Challenge
                  </Tab>
                )}
              </TabList>
              { session.challenge.admin && (
                <TabPanel value={-1} sx = {{flexGrow: 1}}>
                  <Stack spacing={1}>
                    <Input placeholder="Challenge Name" value={name} onChange={e => setName(e.target.value)} required />
                    <Input placeholder="Short Description" value={shortDesc} onChange={e => setShortDesc(e.target.value)}required />
                    <Textarea placeholder="Long Description" minRows={3} value={longDesc} onChange={e => setLongDesc(e.target.value)} required />
                    <Box>
                      <Typography level="body-md" color="neutral">Published</Typography>
                      <Switch
                        checked={published}
                        onChange={(event) => setPublished(event.target.checked)}
                      />
                    </Box>
                  </Stack>
                </TabPanel>
              )}
              <TabPanel value={0} sx = {{flexGrow: 1}}>
                <MessagesPane session={session} sessionManager={sessionManager} sendMessage={async newMessage => {
                  await sessionManager.updateChat(session!, newMessage);
                }}/>
              </TabPanel>
              <TabPanel value={1} sx = {{flexGrow: 1, p: 0}}>
                { session.challenge.admin && (
                  <Alert sx={{mt: 1, mb: 1}}>As the challenge owner, you can update the code in this challenge</Alert>
                )}
                {session.challenge.code === undefined ? (
                    <Alert
                      sx={{ alignItems: 'flex-start' }}
                      variant="soft"
                      color='neutral'
                    >
                      <div>
                        <div>Code not available</div>
                        <Typography level="body-sm" color="neutral">
                          This challenge's source code is kept private!
                        </Typography>
                      </div>
                    </Alert>
                ) : (
                  <CodeEditor readonly={!session.challenge.admin} codeContents={code} onChange={c => setCode(c)} />
                )}
              </TabPanel>
              <TabPanel value={2} sx = {{flexGrow: 1, p: 4}}>
                <Grid container spacing={2} >
                  <Grid xs={12} md={2} lg={1}>
                  <Typography
                        level="body-xs"
                        textTransform="uppercase"
                        fontWeight="lg"
                      >
                        Attempts
                      </Typography>
                      <Typography
                        level="body-xs"
                      >
                        {session.challenge.data.plays}
                      </Typography>
                  </Grid>
                  <Grid xs={12} md={10} lg={11}>
                    <LinearProgress determinate color='neutral' value={100} sx={{mt: '0.7rem'}}/>
                  </Grid>
                  <Grid xs={12} md={2} lg={1}>
                    <Typography
                      level="body-xs"
                      textTransform="uppercase"
                      fontWeight="lg"
                    >
                        Successes
                      </Typography>
                      <Typography
                        level="body-xs"
                      >
                      {session.challenge.data.successes}
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={10} lg={11}>
                    <LinearProgress determinate color='success' value={(100 * session.challenge.data.successes / session.challenge.data.plays)} sx={{mt: '0.7rem'}}/>
                  </Grid>
                  <Grid xs={12} md={2} lg={1}>
                    <div>
                      <Typography
                        level="body-xs"
                        textTransform="uppercase"
                        fontWeight="lg"
                      >
                        Failures
                      </Typography>
                      <Typography
                        level="body-xs"
                      >
                      {session.challenge.data.failures}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid xs={12} md={10} lg={11}>
                    <LinearProgress determinate color='danger' value={(100 * session.challenge.data.failures / session.challenge.data.plays )} sx={{mt: '0.7rem'}}/>
                  </Grid>
                </Grid>
              </TabPanel>
              { session.challenge.admin && (
              <TabPanel value={3} sx = {{flexGrow: 1}}>
                <Stack spacing={1}>
                  { isSaving && (<LinearProgress/>) }
                  <Button onClick={deleteChallege} disabled={isSaving}>Delete this Challenge</Button>
                </Stack>
              </TabPanel>) }
            </Tabs>
          </Card>
        </Grid>
        { session.challenge.admin && hasChanged && (
          <Grid xs={12} sx={{flexShrink: 1}}>
            <Card>
              { isSaving && (<LinearProgress/>) }
              <Button disabled={isSaving} sx={{width: '100%'}} onClick={saveChallenge}>Save Changes</Button>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
