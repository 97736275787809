import * as React from 'react';
import {Box, Button, Link, Typography} from '@mui/joy';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from './TwoSidedLayout';
import { useNavigate } from 'react-router-dom';
import { signInWithRedirect } from 'aws-amplify/auth';

function IntroPage() {
  const navigate = useNavigate();
  return (
    <TwoSidedLayout>
      <Typography color="primary" fontSize="lg" fontWeight="lg">
        The LLM Prompt Injection Playground
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Create and Solve Prompt Injection Challenges
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        InjexAI allows you to create and solve prompt injection challenge.
        Test the robustness of your prompting strategies against a community of
        red team attackers.
      </Typography>
      <Button size="lg" endDecorator={<ArrowForward />} onClick={() => navigate("/challenge")}>
        Browse Challenges
      </Button>
      <Typography>
        Want to create your own challenges? <Link onClick={() => signInWithRedirect({provider: 'Google'})} fontWeight="lg">Sign In</Link>
      </Typography>
    </TwoSidedLayout>
  );
}

export default function HomePage() {
  return (
    <Box
    sx={{
      height: '100vh',
      overflowY: 'scroll',
      scrollSnapType: 'y mandatory',
      '& > div': {
        scrollSnapAlign: 'start',
      },
    }}
  >
    <IntroPage />
  </Box>
  );
}
