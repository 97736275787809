import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Challenge } from '../types';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/joy';

export interface ChallengeCardProps {
  challenge: Challenge;
}

export default function ChallengeCard({challenge} : ChallengeCardProps) {

  const navigate = useNavigate();
  
  return (
    <Card sx={{ width: {xs: '100%', lg: '31%', md: '47%'} }}>
      <div>
        <Typography level="title-lg">{challenge.name}</Typography>
        <Typography level="body-sm" sx={{width: 'calc(100% - 3rem)'}}>{challenge.description}</Typography>
        <Box
          sx={{ position: 'absolute', top: '0.875rem', right: '0.875rem' }}
        >
        {
          (challenge.difficulty === undefined) ? (
          <HelpRoundedIcon />
          ) :
          (
            <CircularProgress size="md" determinate value={challenge.difficulty!}>
              {challenge.difficulty! > 30 ? (challenge.difficulty! > 65 ? "HARD" : 'MED') : 'EASY'}
            </CircularProgress>
          )
        }
        </Box>
      </div>
      <CardContent orientation="vertical" sx={{display: 'flex'}}>
        <div style={{flex: 1, flexGrow: 1}}>
          <Typography level="body-sm">{challenge.fullDetails}</Typography>
        </div>
        <Button
          variant="solid"
          size="md"
          color="primary"
          aria-label="Play Challenge"
          onClick={() => navigate("/challenge/" + challenge.id)}
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
        >
          Play
        </Button>
      </CardContent>
    </Card>
  );
}