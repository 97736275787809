import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import ContentWindow from './components/ContentWindow';
import theme from './theme';
import { Session } from './types';
import { SessionManager } from './Sessions';
import HomePage from './components/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ChallengeBrowser from './components/ChallengeBrowser';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import NewChallenge from './components/NewChallenge';
import { Authenticator } from '@aws-amplify/ui-react';
import { Snackbar } from '@mui/joy';

Amplify.configure({
  Auth: {
    Cognito: {
        userPoolId: awsExports.USER_POOL_ID,
        userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: awsExports.COGNITO_DOMAIN,
            scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [window.location.origin],
            redirectSignOut: [window.location.origin],
            responseType: 'token',
            providers: ['Google']
          },
          email: true
        }
    }
  }
});

const sessionManager = new SessionManager();

export default function InjexAI() {
  const [session, setSession] = React.useState<Session>();
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    sessionManager.addSessionHook(setSession);
    sessionManager.addErrorListener(setError);
  }, [setSession]);
  
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <CssVarsProvider  defaultMode="system" disableTransitionOnChange theme={theme}>
          <CssBaseline />
          <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Sidebar sessionManager={sessionManager}/>
            {
              // Only visible on small devices
              (<Header /> )
            }
            <Box component="main" className="MainContent" sx={{ flex: 1, display: 'flex', paddingTop: 'var(--Header-height)', flexDirection: 'column'}}>
              <Routes>
                <Route index element={<HomePage/>} />
                <Route path="challenge" element={<ChallengeBrowser sessionManager={sessionManager}/>} />
                <Route path="challenge/new" element={<NewChallenge sessionManager={sessionManager}/>} />
                <Route path="challenge/:challengeId" element={<ContentWindow session={session!} sessionManager={sessionManager}/>} />
              </Routes>
            </Box>
          </Box>
          <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={error !== undefined}
              variant='soft'
              autoHideDuration={5000}
              color='danger'
              onClose={()=>{setError(undefined);}}
            >
              {error}
          </Snackbar>
        </CssVarsProvider>
      </BrowserRouter>
    </Authenticator.Provider>
  );
}
