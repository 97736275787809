import React from "react";
import AceEditor from "react-ace";

import { useColorScheme } from '@mui/joy/styles';

import "brace/mode/typescript";
import "brace/theme/clouds";
import "brace/theme/clouds_midnight";

export interface EditorProps {
    codeContents: string;
    onChange: (codeContents: string) => void;
    readonly: boolean;
}

const CodeEditor = ({codeContents, onChange, readonly} : EditorProps) => {

  const { mode, systemMode } = useColorScheme();
  const colorMode = mode === "system" ? systemMode : mode

  return (
    <AceEditor
        mode="javascript"
        readOnly={readonly}
        theme={colorMode === "light" ? "clouds" : "clouds_midnight"}
        width="100%"
        name="ace-editor"
        editorProps={{ $blockScrolling: false }}
        value={codeContents}
        onChange={s => onChange(s)}
        style={{
          backgroundColor: 'var(--joy-palette-neutral-10)'
        }}
        showGutter={true}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2
        }}
      />
  );
};

export default CodeEditor;
