import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ViewListIcon from '@mui/icons-material/ViewListRounded';

import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { SearchResult } from '../types';
import { SessionManager } from '../Sessions';
import { CircularProgress, Stack, useColorScheme } from '@mui/joy';
import logo from "../logo.svg";
import logoLight from "../logo-light.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  signOut,
  signInWithRedirect,
  FetchUserAttributesOutput,
  fetchUserAttributes
} from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';

const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

export interface SidebarProps {
  sessionManager: SessionManager;
}

export default function Sidebar({sessionManager}: SidebarProps) {


  const [allResults, setAllResults] = React.useState<SearchResult>();
  const [visibleSearchResults, setVisibleSearchResults] = React.useState<SearchResult>();

  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [searchTask, setSearchTask] = React.useState<NodeJS.Timeout>();
  const [searching, setSearching] = React.useState<string>();
  const [searchInProgress, setSearchInProgress] = React.useState<boolean>(false);
  const [userAttributes, setUserAttributes] = React.useState<FetchUserAttributesOutput>();
  const [initials, setInitials] = React.useState<string>("");

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const { mode, systemMode } = useColorScheme();
  const colorMode = mode === "system" ? systemMode : mode
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (authStatus !== 'authenticated') {
      setUserAttributes(undefined);
      setInitials("");
    } else {
      fetchUserAttributes().then(a => {
        setUserAttributes(a);
        if (a.name !== undefined) {
          let initials = Array.from(a.name!.matchAll(initialsRegex));
          setInitials(initials.map(w => w[1]).join(""))
        } else {
          setInitials("");
        }
      });
    }
  }, [authStatus, setUserAttributes])

  React.useEffect(() => {
    const handle = sessionManager.addChallengeListHook(c => setAllResults(c));
    return () => sessionManager.removeChallengeListHook(handle);
  }, [sessionManager]);

  React.useEffect(() => {
    // Wait 300ms before searching.
    if (searchFilter !== searching || ( searchFilter === "" && allResults != visibleSearchResults)) {
      setSearching(searchFilter);
      setSearchInProgress(true);
      if (searchTask !== undefined) {
        clearTimeout(searchTask);
      }
      if (searchFilter === "") {
        setSearchInProgress(false);
        setVisibleSearchResults(allResults);
      } else {
        setSearchTask(
          setTimeout(() => {
            sessionManager
            .searchChallenges(searchFilter, undefined)
            .then(cs => {
              setVisibleSearchResults(cs);
              setSearchInProgress(false);
            })
          }, 300)
        );
      }
    }
  }, [allResults, searchFilter, searchTask, searching, sessionManager]);
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <img alt="InjexAI" src={colorMode === "dark" ? logo : logoLight} style={{maxWidth: 'calc(100% - 48px)', maxHeight: '32px'}}/>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" value={searchFilter !== "" ? searchFilter : undefined} onChange={e => setSearchFilter(e.target.value)} />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >

      <List
        size="sm"
        sx={{
          flexGrow: 0,
          gap: 1,
          '--List-nestedInsetStart': '30px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
        }}
      >
        <ListItem>
          <ListItemButton
            selected={location.pathname === "/"}
            onClick={() => navigate("/")}
            >
            <HomeRoundedIcon />
            <ListItemContent>
              <Typography level="title-sm">Home</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={location.pathname === "/challenge"}
            onClick={() => navigate("/challenge")}
            >
            <ViewListIcon />
            <ListItemContent>
              <Typography level="title-sm">Browse Challenges</Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      { (searchInProgress) && (<Stack alignItems="center" sx={{pt: 4, pb: 4}}><CircularProgress/></Stack>) }

      { Object.entries(visibleSearchResults ?? {})
            .map(x => {
              const section = x[0];
              const challanges = x[1];
              return (<List
                key={`section-${section}`}
                size="sm"
                sx={{
                  gap: 1,
                  flexGrow: 0,
                  '--List-nestedInsetStart': '30px',
                  '--ListItem-radius': (theme) => theme.vars.radius.sm,
                }}
              >
                <Typography
                  level="body-xs"
                  textTransform="uppercase"
                  fontWeight="lg"
                  mb={1}
                >
                  {section}
                </Typography>
                {challanges.map(c => (<ListItem 
                    key={`item-${section}`}>
                    <ListItemButton
                      selected={location.pathname === `/challenge/${c.id}`}
                      onClick={() => sessionManager.switchChallenge(c, navigate)}
                      >
                      <ListItemContent>
                        <Typography level="title-sm">{c.name}</Typography>
                        <Typography level="body-sm">{c.description}</Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>);
            })
        }
        <Box sx={{
          flexGrow: 1,
        }}/>
        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton
                onClick={() => navigate("/challenge/new")}
                selected={location.pathname === "/challenge/new"}
                disabled={authStatus !== 'authenticated'}>
              <AddRoundedIcon />
              Create New Challenge
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {
          userAttributes === undefined ? (
            <Avatar variant="outlined" size="sm" />
          ) : (initials === "" ? (
            <Avatar variant="outlined" color="primary" size="sm" />
          ) : (<>
            <Avatar variant="outlined" color="primary" size="sm">{initials}</Avatar>
          </>))
        }
        <Box sx={{ minWidth: 0, flex: 1, overflow: 'hidden'}}>
          {
            userAttributes === undefined ? (
              <Typography level="title-sm">Guest User</Typography>
            ) : (<>
              <Typography level="title-sm">{(userAttributes.name || "Logged In")}</Typography>
              <Typography level="body-xs">{userAttributes.email}</Typography>
            </>)
          }
        </Box>
        {authStatus === 'unauthenticated' ? (
          <IconButton size="sm" variant="plain" color="neutral" onClick={() => signInWithRedirect({
            provider: 'Google'
          })}>
            <LoginRoundedIcon />
          </IconButton>
        ) : (<IconButton size="sm" variant="plain" color="neutral" onClick={() => signOut()}>
            <LogoutRoundedIcon />
          </IconButton>) }

      </Box>
    </Sheet>
  );
}
